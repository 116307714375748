/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { SIZE_TO_VALUE } from './Icons.constants';
// THIS FILE IS GENERATED, DO NOT EDIT IT DIRECTLY
const PlusHeavy = ({
  color = 'currentColor',
  title = '',
  id,
  size = 'md',
  ...props
}) => {
  const titleId = id || undefined;
  const role = title !== '' ? 'img' : undefined;
  const ariaHidden = role !== 'img';
  const width = SIZE_TO_VALUE[size];
  const height = SIZE_TO_VALUE[size];
  return <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" role={role} aria-hidden={ariaHidden} width={width} height={height} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path d="M16 8C16 8.6 15.6 9 15 9H9V15C9 15.6 8.6 16 8 16C7.4 16 7 15.6 7 15V9H1C0.4 9 0 8.6 0 8C0 7.4 0.4 7 1 7H7V1C7 0.4 7.4 0 8 0C8.6 0 9 0.4 9 1V7H15C15.6 7 16 7.4 16 8Z" fill={color} /></svg>;
};
PlusHeavy.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.string,
  size: PropTypes.string
};
export default PlusHeavy;