import { setDecimalPrecision } from '../math';

export const ROOT_FONT_SIZE = 16;

export const BREAKPOINTS = {
  xs: 480,
  sm: 768,
  md: 960,
  lg: 1280,
};

export const REFRESH_BREAKPOINTS = {
  xs: 0,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
};

export const pxToRem = value => {
  return setDecimalPrecision((1 / ROOT_FONT_SIZE) * value, 3);
};

// Generates a CSS value for scaling elements/text relative to viewport width.
// https://www.smashingmagazine.com/2022/01/modern-fluid-typography-css-clamp/
export const generateFluidClampString = (
  minPx,
  maxPx,
  minBreakpoint,
  maxBreakpoint,
  horizontal = true
) => {
  const viewportUnit = horizontal ? 'vw' : 'vh';

  const minRem = pxToRem(minPx);
  const maxRem = pxToRem(maxPx);
  const viewportCoefficient = setDecimalPrecision(
    (ROOT_FONT_SIZE * 100 * (maxRem - minRem)) / (maxBreakpoint - minBreakpoint),
    2
  );
  const remCoefficient = setDecimalPrecision(
    minRem - (minBreakpoint * (maxRem - minRem)) / (maxBreakpoint - minBreakpoint),
    2
  );

  return `clamp(${minRem}rem, ${viewportCoefficient}${viewportUnit} + ${remCoefficient}rem, ${maxRem}rem)`;
};

// Removes undefined and null values from JSX className assignments.
export function classNames(...classes) {
  return classes
    .flat()
    .filter(Boolean)
    .join(' ');
}

// So we can use JS to get values from SCSS maps through CSS module imports.
// https://github.com/D-Pow/react-app-boilerplate/blob/515c84306ba8370523f6c068635b54425f98ea19/src/utils/Scss.js#L31
export const parseScssMapToJs = scssStr => {
  if (!scssStr) {
    return {};
  }
  return JSON.parse(scssStr.replace(/(^['"])|(['"]$)/g, ''));
};

const CSS_ASPECT_RATIO_REGEX = /^(auto )?(\d+(\.?)\d{0,15})( ?\/ ?)(\d+(\.?)\d{0,15})$/;
export const aspectRatioToValidCssString = ratio => {
  if (['string', 'number'].includes(typeof ratio)) {
    if (CSS_ASPECT_RATIO_REGEX.test(ratio.toString())) return ratio;

    const ratioNumber = parseFloat(ratio);
    if (!Number.isNaN(ratioNumber)) return `${ratioNumber} / 1`;
  }

  return undefined;
};

export const getHeaderOffsetPx = (buffer = 0) => {
  const width = window?.innerWidth;
  if (width) {
    // can't use calc values in JS, so we have to get the custom property values
    const customTopStickyPx = getComputedStyle(document.documentElement).getPropertyValue('--custom-top-sticky-content-px') || 0;
    const bannerHeight = getComputedStyle(document.documentElement).getPropertyValue('--banner-height-px');
    const navHeight = getComputedStyle(document.documentElement).getPropertyValue('--nav-height-px');
    const navLowerDeckHeight = getComputedStyle(document.documentElement).getPropertyValue('--nav-lower-deck-height-px');

    // Desktop
    const fullHeaderHeight =
        parseInt(navHeight, 10) +
        parseInt(bannerHeight, 10) +
        parseInt(navLowerDeckHeight, 10) +
        parseInt(customTopStickyPx, 10) +
        parseInt(buffer, 10);

    return fullHeaderHeight || 0;
  }

  return 0;
};
