import { useEffect } from 'react';

export const useWindowLocationChange = callback => {
  useEffect(() => {
    callback();
    window.addEventListener('hashchange', callback);
    window.addEventListener('popstate', callback);
    window.addEventListener('pushstate', callback);
    window.addEventListener('resize', callback);

    return () => {
      window.removeEventListener('hashchange', callback);
      window.removeEventListener('popstate', callback);
      window.removeEventListener('pushstate', callback);
      window.removeEventListener('resize', callback);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
