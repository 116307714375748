'use client';

import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import isChromatic from 'chromatic/isChromatic';
import { classNames } from 'src/utils/css';
import { useComponentContext } from 'src/components/layout/ComponentContext';
import { useTranslationContext, useTranslation } from 'src/components/contexts/TranslationContext';
import { EVENT_NAMES, trackAnalyticsEvent } from 'src/services/analytics-service';
import { OneFieldSubscribeForm } from 'src/components/common/OneFieldSubscribeForm';
import { gql, useMutation, ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import getFrameworkEnvVar from 'lib/get-framework-env-var';
import { ParticleEmitter } from './particle-emitter/particle-emitter.component';
import { markdownLegalText_ } from './TLDRSubscribeForm.module.scss';
import { translations } from './TLDRSubscribeForm.translations';
import TLDREmoji from './media/TLDREmoji.png';

const client = new ApolloClient({
  uri: getFrameworkEnvVar('INVEST_GRAPHQL_API_PUBLIC_URL'),
  cache: new InMemoryCache(),
});

const SUBSCRIBE_MUTATION = gql`
  mutation NewsletterPreferenceUpdate(
    $email: String!
    $subscribed: Boolean!
    $newsletter: String!
  ) {
    newsletterPreferenceUpdate(
      input: {
        email: $email
        subscribed: $subscribed
        newsletter: $newsletter
        signupSource: "tldr_web"
      }
    ) {
      status
    }
  }
`;

const TLDRSubscribeForm = ({ className, legalTextClassName, xplode = false, buttonLabel }) => {
  const lastMouseXCoordinate = useRef(0);
  const formRef = useRef();
  const [subscribeToTLDR] = useMutation(SUBSCRIBE_MUTATION);

  const { appendTranslationKeys } = useTranslationContext();
  appendTranslationKeys(translations);

  const componentContext = useComponentContext();

  const handleSubmit = async ({ email: emailValue }) => {
    trackAnalyticsEvent(EVENT_NAMES.TLDR_SIGNUP, {}, componentContext);

    return subscribeToTLDR({
      variables: {
        email: emailValue,
        subscribed: true,
        newsletter: 'tldr',
        source: componentContext.name,
      },
    });
  };

  const handleError = (e, { setFormError, defaultMessage }) => setFormError(defaultMessage);

  const handleSuccess = () => {
    if (!xplode || isChromatic()) {
      return;
    }

    // Emoji xplosion
    const x = window.innerWidth / 2 - 50;
    const y = window.innerHeight;

    ParticleEmitter({
      imgUrl: TLDREmoji,
      pageX: x,
      pageY: y,
      count: 35,
      maxScale: 2.5,
    });
  };

  const updateMouseCoordinates = e => {
    lastMouseXCoordinate.current = e.pageX;
  };

  useEffect(() => {
    const submitButton = formRef.current.querySelector('button[type="submit"]');
    submitButton.addEventListener('click', updateMouseCoordinates);

    return () => {
      submitButton.removeEventListener('click', updateMouseCoordinates);
    };
  }, []);

  return (
    <OneFieldSubscribeForm
      ref={formRef}
      className={className}
      buttonLabel={buttonLabel}
      inputLabel={useTranslation('tldr-form::input_label')}
      disclaimerText={useTranslation('tldr-form::consent')}
      disclaimerClassName={classNames(markdownLegalText_, legalTextClassName)}
      onSubmitAction={handleSubmit}
      onSuccess={handleSuccess}
      onSubmitError={handleError}
    />
  );
};

TLDRSubscribeForm.propTypes = {
  xplode: PropTypes.bool,
  className: PropTypes.string,
  legalTextClassName: PropTypes.string,
  buttonLabel: PropTypes.node,
};

const TLDRSubscribeFormProvider = props => (
  <ApolloProvider client={client}>
    <TLDRSubscribeForm {...props} />
  </ApolloProvider>
);

export { TLDRSubscribeFormProvider as TLDRSubscribeForm };
