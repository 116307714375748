'use client';

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'src/utils/css';
import { getInputFormatter } from 'src/utils/formatters';
import { useTranslationContext, useTranslation } from 'src/components/contexts/TranslationContext';
import { AlertCircle, CheckCircle, Show, Hide } from 'src/components/common/Icons';
import { useComponentContext } from 'src/components/layout/ComponentContext';
import {
  formInputWrapper_,
  alignWrapperForInlineLabel_,
  phoneCountryCodePlaceholder_,
  formInputLabel_,
  formInput_,
  withLabel_,
  noLabel_,
  formInputValid_,
  formInputInvalid_,
  passwordInput_,
  squaredCorners_,
  small_,
  large_,
  eyeButton_,
  iconContainer_,
  messageContainer_,
  messageLeftAligned_,
  darkTheme_,
  alertMessage_,
  message_,
} from './FormInputField.module.scss';
import { translations } from './FormInputField.translations';
import { FormFieldLabel } from '../FormFieldLabel';

/**
 * The classes that mark this input as valid/invalid are purposely using different logic when used with FinalForm.
 *
 * The difference is that the checkmark is added more eagerly (as you type) to indicate valid
 * input, while the error is shown only on blur.
 *
 * When this input is used outside FinalForm, the user can control when the field is highlighted as valid/invalid
 * by passing the showValid/showInvalid props, as well as display an error message to the user by passing the
 * showError prop.
 */
const getShouldShowValid = ({ showValid, error, submitError, dirty }) =>
  showValid || (dirty && !(error || submitError));

const getShouldShowInvalid = ({ showInvalid, error, submitError, touched, dirty }) =>
  showInvalid || (touched && dirty && (error || submitError));

const getShouldDisplayError = ({ showError, error, submitError, touched, dirty, active }) =>
  showError || ((error || submitError) && touched && dirty && !active);

const SIZES = {
  sm: {
    className: small_,
    inputTextSize: 'ws-text-lg',
  },
  md: {
    inputTextSize: 'ws-text-xl',
  },
  lg: {
    className: large_,
    inputTextSize: 'ws-text-2xl',
  }
};

export const FormInputField = ({
  id,
  type = 'text',
  label,
  size = 'md',
  squared = false,
  message,
  isRequired = true,
  showRequiredLabel = false,
  customFormatter,
  onValueUpdate,
  // "input" props
  name,
  onBlur,
  onChange,
  onFocus,
  value,
  autoComplete,
  input = {}, // default object from FinalForm, if used
  placeholder,
  // "meta" props
  error,
  showValid, // custom, not used by FinalForm
  showInvalid, // custom, not used by FinalForm
  showError, // custom, not used by FinalForm
  meta = {}, // default object from FinalForm, if used
  disabled = false, // disable the input if needed
  hasExternalLabel = false,
  tooltipDescription,
}) => {
  const inputRef = useRef();
  const inputValueRef = useRef(value);
  const { appendTranslationKeys, locale } = useTranslationContext();
  appendTranslationKeys(translations);
  const [showPassword, setShowPassword] = useState(false);
  const isPassword = type === 'password';
  const isTel = type === 'tel';

  const _type = isPassword && showPassword ? 'text' : type;

  const handleChange = e => {
    const { value: newValue } = e.target;
    // Keep track of what's in the input vs what the formatted value is
    // Used to determine when to run the onValueUpdate callback (see useEffect below)
    inputValueRef.current = newValue;
    const formatter = customFormatter || getInputFormatter[type];
    const formattedValue = formatter ? formatter(newValue, { locale }) : newValue;
    // Passing the original event and formatted value
    if (onChange) onChange(e, formattedValue);

    return formattedValue;
  };

  const resolvedMeta = {
    error,
    showValid,
    showInvalid,
    showError,
    ...meta,
  };

  const resolvedInput = {
    name,
    onBlur,
    onChange: handleChange,
    onFocus,
    value,
    placeholder,
    ref: inputRef,
    ...input,
  };

  const showFieldIsValid = getShouldShowValid(resolvedMeta);
  const showFieldIsInvalid = getShouldShowInvalid(resolvedMeta);
  const showErrorMessage = getShouldDisplayError(resolvedMeta);

  const resolvedError = resolvedMeta.error || resolvedMeta.submitError;
  const showPasswordLabel = useTranslation('form-input-field::password::show-password');
  const hidePasswordLabel = useTranslation('form-input-field::password::hide-password');
  const requiredText = useTranslation('form-input-field::required');

  const { shouldUseDarkTheme } = useComponentContext();
  const isInlineLabel = hasExternalLabel ? false : !squared;

  const inputId = id;
  const labelId = `${inputId}-label`;
  const messageId = `${inputId}-message`;
  const sizeConfig = SIZES[size];

  useEffect(() => {
    // Run the onValueUpdate callback if the value has changed from what is in the input
    if (onValueUpdate && value && value !== inputValueRef.current) {
      onValueUpdate(value, locale, inputRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, inputValueRef.current]);

  return (
    <>
      <div
        className={classNames(
          formInputWrapper_,
          isInlineLabel && alignWrapperForInlineLabel_,
          squared && squaredCorners_,
          shouldUseDarkTheme && darkTheme_,
          sizeConfig?.className,
          isInlineLabel ? 'ws-text-lg' : sizeConfig?.inputTextSize,
        )}
      >
        {isTel && (
          <div className={phoneCountryCodePlaceholder_} aria-hidden="true">
            +1
          </div>
        )}
        <input
          {...resolvedInput}
          disabled={disabled}
          id={inputId}
          type={_type}
          required={isRequired}
          autoComplete={autoComplete}
          className={classNames(
            formInput_,
            label ? withLabel_ : noLabel_,
            isPassword && passwordInput_,
            showFieldIsValid && formInputValid_,
            showFieldIsInvalid && formInputInvalid_
          )}
          aria-labelledby={labelId}
          aria-describedby={message && messageId}
          aria-required={isRequired}
        />
        {label && (
          <FormFieldLabel
            htmlFor={inputId}
            label={label}
            labelId={labelId}
            message={message}
            messageId={messageId}
            requiredLabel={requiredText}
            showRequiredLabel={showRequiredLabel}
            className={formInputLabel_}
            isInline={isInlineLabel}
            tooltipDescription={tooltipDescription}
          />
        )}
        {(showFieldIsValid || showFieldIsInvalid || isPassword) && (
          <div className={iconContainer_}>
            {showFieldIsInvalid && <AlertCircle size="lg" color="var(--color-text-error)" />}
            {showFieldIsValid && <CheckCircle size="lg" color="var(--color-text-success)" />}
            {isPassword && (
              <button
                type="button"
                className={classNames(eyeButton_)}
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <Hide size="xl" title={hidePasswordLabel} />
                ) : (
                  <Show size="xl" title={showPasswordLabel} />
                )}
              </button>
            )}
          </div>
        )}
      </div>
      <div
        className={classNames(
          ((showErrorMessage && resolvedError) || (isInlineLabel && message)) && messageContainer_,
          shouldUseDarkTheme && darkTheme_,
          squared && messageLeftAligned_,
          'ws-text-sm'
        )}
      >
        <div role="alert" aria-live="assertive" className={alertMessage_}>
          {showErrorMessage && resolvedError}
        </div>
        {isInlineLabel && (
          <div role="status" aria-live="polite" className={message_} id={messageId}>
            {/* Only show if there isn't an error message */}
            {!(showErrorMessage && resolvedError) && message}
          </div>
        )}
      </div>
    </>
  );
};

FormInputField.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['text', 'password', 'email', 'tel']),
  label: PropTypes.string,
  squared: PropTypes.bool,
  message: PropTypes.node,
  isRequired: PropTypes.bool,
  showRequiredLabel: PropTypes.bool,
  customFormatter: PropTypes.func,
  autoComplete: PropTypes.string,
  // The following should only be provided if you are _not_ using FinalForm
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  showValid: PropTypes.bool,
  showInvalid: PropTypes.bool,
  showError: PropTypes.bool,
  placeholder: PropTypes.string,
  // If using FinalForm, just pass these render arguments from <Field>
  meta: PropTypes.shape({}),
  input: PropTypes.shape({}),
  disabled: PropTypes.bool,
  hasExternalLabel: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
};
