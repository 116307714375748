import extend from 'lodash/extend';
import { stubAnalyticsMethod } from 'lib/segment';
import { pascalCaseToSpacedLowerCase } from 'src/utils/strings';
import { getDelocalizedPath } from 'src/utils/localization';
import { isWindowDefined } from 'src/utils/window';
import getFrameworkEnvVar from 'lib/get-framework-env-var';
import { EVENTS as LEGACY_EVENTS } from './analytics-constants';

export const EVENT_NAMES = {
  NAV_CTA_CLICKED: 'nav_cta_clicked',
  NAV_DROPDOWN_OPENED: 'nav_dropdown_opened',
  NAV_DROPDOWN_BACK: 'nav_dropdown_back',
  PRIMARY_CTA_CLICKED: 'primary_cta_clicked',
  TERTIARY_CTA_CLICKED: 'tertiary_cta_clicked',
  LINK_CLICKED: 'link_clicked',
  LANGUAGE_SELECTOR_OPENED: 'footer_language_selector_opened',
  LANGUAGE_SELECTOR_CLICKED: 'footer_language_changed',
  NAV_MENU_DRAWER_OPENED: 'nav_menu_drawer_opened',
  FAQ_OPENED: 'faq_opened',
  FAQ_CLOSED: 'faq_closed',
  FORM_SUBMIT_SUCCESS: 'form_submit_success',
  FORM_SUBMIT_FAILURE: 'form_submit_failure',
  FORM_ENGAGEMENT: 'form_engagement',
  BANNER_DISPLAYED: 'banner_displayed',
  BANNER_DISMISSED: 'banner_dismissed',
  DISMISSIBLE_DISPLAYED: 'dismissible_displayed',
  DISMISSIBLE_DISMISSED: 'dismissible_dismissed',
  FLOATING_NOTIFICATION_DISPLAYED: 'floating_notification_displayed',
  FLOATING_NOTIFICATION_DISMISSED: 'floating_notification_dismissed',
  FLOATING_NOTIFICATION_CTA_CLICKED: 'floating_notification_cta_clicked',
  CHATBOT_DRAWER_OPENED: 'chatbot_drawer_opened',
  CHATBOT_DRAWER_CLOSED: 'chatbot_drawer_closed',
  CHATBOT_START_CHAT_CTA_CLICKED: 'chatbot_start_chat_cta_clicked',
  TLDR_SIGNUP: 'tldr_signup',
  // Mortgage Calculator
  LOAN_TYPE_TAB_SELECT: 'loan_type_tab_select',
  PROPERTY_PRICE_INPUT_UPDATE: 'property_price_input_update',
  MORTGAGE_AMOUNT_INPUT_UPDATE: 'mortgage_amount_input_update',
  TERM_INPUT_UPDATE: 'term_input_update',
  DOWN_PAYMENT_PERCENT_INPUT_UPDATE: 'down_payment_percent_input_update',
  DOWN_PAYMENT_DOLLAR_INPUT_UPDATE: 'down_payment_dollar_input_update',
  AMORTIZATION_INPUT_UPDATE: 'amortization_input_update',
  PAYMENT_FREQUENCY_INPUT_UPDATE: 'payment_frequency_input_update',
  TRACK_SLIDER_INPUT: 'track_slider_input',
  CLIENT_STATUS_TAB_UPDATE: 'client_status_tab_update',
  FULL_BREAKDOWN_TOGGLE_DESKTOP: 'full_breakdown_desktop_toggle',
  FULL_BREAKDOWN_TOGGLE_MOBILE_UPPER: 'full_breakdown_toggle_mobile_upper',
  FULL_BREAKDOWN_TOGGLE_MOBILE_LOWER: 'full_breakdown_toggle_mobile_lower',
};

export const COMPONENT_NAMES = {
  MOBILE_NAV: 'MobileNav',
  DESKTOP_NAV: 'DesktopNav',
  NOTIFICATION_BANNER: 'NotificationBanner',
  FOOTER: 'Footer',
};

/*
 * Modern tracking event, designed to move data to Segment and Redshift correctly.
 *
 * Additionally, logs events to console in storybook for development purposes
 *
 * @param {eventName} string - should be from the EVENT_NAMES dictionary above
 * @param {attributes} object - an object with additional event data. Should be limited to:
 *   {
 *     label {string}:
 *        The label for the event trigger. Usually the link or button text.
 *     component {string}:
 *        A lower case (with spaces) name of the component the event was fired from.
 *     href {string}:
 *        The destination URL for link clicks
 *     section_index {string}:
 *        The index of section (starts at 0) where the event was triggered
 *   }
 * @param {context} ComponentContext - an instance of component context to provide additional context
 */
export const trackAnalyticsEvent = (eventName, attributes, context) => {
  const mergedData = {
    section_index: context?.sectionIndex,
    component_name: context?.name,
    ...attributes,
  };
  const componentName = mergedData.component_name;
  if (componentName) {
    mergedData.component_name = pascalCaseToSpacedLowerCase(componentName);
  }

  if (!isWindowDefined() || !getFrameworkEnvVar('SEGMENT_KEY')) {
    /* eslint-disable no-console */
    console.group(`Analytics service saw a track event with the following properties:`);
    console.table({ eventName, ...mergedData });
    console.groupEnd();
    /* eslint-enable no-console */
    return;
  }

  if (!window?.analytics?.track && getFrameworkEnvVar('SEGMENT_KEY')) {
    // Stub analytics queue for track calls only
    // The Segment snippet in <SegmentIoScript> also does this, but if we're
    // here then it hasn't run yet
    stubAnalyticsMethod('track');
  }

  window.analytics.track(eventName, mergedData, { custom_properties: JSON.stringify(mergedData) });
};

export const getDefaultAttributes = () => ({
  Platform: 'WWW',
  'Platform Type': 'Client-side',
  delocalized_path: getDelocalizedPath(window.location.pathname),
});

export const aliasUser = userId => {
  if (!isWindowDefined() || !window.analytics?.alias) {
    return;
  }

  window.analytics.alias(userId);
};

export const getAnonymousId = () => {
  if (!isWindowDefined() || !window.analytics?.user) {
    return null;
  }

  return window.analytics.user().anonymousId();
};

export const legacy__trackEvent = (eventName, attributes) => {
  if (!isWindowDefined() || !window.analytics?.track) {
    return;
  }

  window.analytics.track(eventName, extend(getDefaultAttributes(), attributes));
};

export const trackArticleClicked = ({ contentCollection, articleId, context, contextDetail }) => {
  if (contentCollection === 'magazine') {
    trackMagazineArticleClicked({ articleId, context, contextDetail });
  }
};

/**
 * Context is where the article was being displayed or which list it was in
 * e.g. 'popular', 'related', etc.
 *
 * Context Detail is meant to further disambiguate where on the page it is
 * e.g. 'recommended-top', 'side-1',
 */
const trackMagazineArticleClicked = ({ articleId, context, contextDetail }) => {
  legacy__trackEvent(LEGACY_EVENTS.MAGAZINE_ARTICLE_CLICKED, {
    article_id: articleId,
    context,
    context_detail: contextDetail,
  });
};

export const trackMagazineEmailCaptured = ({ context, contextDetail }) => {
  legacy__trackEvent(LEGACY_EVENTS.MAGAZINE_EMAIL_CAPTURED, {
    context,
    context_detail: contextDetail,
  });
};

export const trackContentHubEmailCaptured = ({ context, contextDetail }) => {
  legacy__trackEvent(LEGACY_EVENTS.CONTENT_HUB_EMAIL_CAPTURED, {
    context,
    context_detail: contextDetail,
  });
};

export const trackSocialShareClicked = ({ platform, userContext, source }) => {
  legacy__trackEvent(LEGACY_EVENTS.SOCIAL_SHARE_CLICKED, {
    platform,
    userContext,
    source,
  });
};
