import React from 'react';
import PropTypes from 'prop-types';
import { useComponentContext } from 'src/components/layout/ComponentContext';
import { classNames } from 'src/utils/css';
import {
  primaryButton,
  secondaryButton,
  darkThemeButton,
  standaloneIcon_,
  sizeXs,
  sizeSm,
  sizeLg,
  sizeXl,
  sizeXxl,
  disabled as disabledClass,
  icon_,
} from './CTAButton.module.scss';

export const VARIANT_TO_CLASS = {
  primary: primaryButton,
  secondary: secondaryButton,
};

export const SIZE_TO_CLASS = {
  xs: sizeXs,
  sm: sizeSm,
  lg: sizeLg,
  xl: sizeXl,
  xxl: sizeXxl,
};

export const ICON_POSITION_TO_CLASS = {
  left: undefined,
  right: undefined,
  standalone: standaloneIcon_,
};

export const getLinkThemeClass = (themePropOverride, darkThemeContext) => {
  if (themePropOverride === 'dark' || (darkThemeContext && themePropOverride !== 'light')) {
    return darkThemeButton;
  }

  return '';
};

export const ButtonLabel = ({ children, icon, iconPosition }) => {
  const iconWithClass = icon
    ? React.cloneElement(icon, { className: classNames(icon.props.className, icon_) })
    : null;

  if (iconPosition === 'standalone') {
    return (
      <span>
        <span className="visually-hidden">{children}</span>
        {iconWithClass}
      </span>
    );
  }

  return (
    <span>
      {iconPosition === 'left' && iconWithClass}
      <span>{children}</span>
      {iconPosition === 'right' && iconWithClass}
    </span>
  );
};

export const CTAButton = ({
  variant = 'primary',
  size,
  type = 'button',
  onClick,
  className,
  disabled = false,
  children,
  icon,
  iconPosition = 'right',
  theme = 'none',
  ...rest
}) => {
  const { shouldUseDarkTheme } = useComponentContext();
  const themeClassName = getLinkThemeClass(theme, shouldUseDarkTheme);

  const handleClick = e => {
    if (onClick) onClick(e);
  };

  const finalSize = size ?? (variant === 'secondary' ? 'xl' : 'xxl');

  return (
    <button
      className={classNames(
        VARIANT_TO_CLASS[variant],
        SIZE_TO_CLASS[finalSize],
        disabled && disabledClass,
        ICON_POSITION_TO_CLASS[iconPosition],
        themeClassName,
        className
      )}
      // eslint-disable-next-line react/button-has-type
      type={type}
      onClick={e => handleClick(e)}
      disabled={disabled}
      {...rest}
    >
      <ButtonLabel icon={icon} iconPosition={iconPosition}>
        {children}
      </ButtonLabel>
    </button>
  );
};

// CTAButton props
CTAButton.propTypes = {
  variant: PropTypes.oneOf(Object.keys(VARIANT_TO_CLASS)),
  size: PropTypes.oneOf(Object.keys(SIZE_TO_CLASS)),
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  onClick: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  iconPosition: PropTypes.oneOf(Object.keys(ICON_POSITION_TO_CLASS)),
  theme: PropTypes.oneOf(['none', 'dark', 'light']),
};
