'use client';

import React, { forwardRef } from 'react';
import Link from 'next/link';
import { getFramework } from 'lib/get-framework';
import { pathnameHasLocale, stripTrailingSlash } from 'src/utils/urls';
import { useTranslationContext } from 'src/components/contexts/TranslationContext';
import { GATSBY_PATHS } from './localized-link.constants';

const generateToPath = (to, locale) => {
  // Ensure we do not generate links to paths with trailing slashes
  const _to = stripTrailingSlash(to);

  // Path is already acceptable, e.g. '/en-ca/example'
  if (pathnameHasLocale(_to)) return _to;

  // stripTrailingSlash() preserves '/', so check for that case
  if (_to === '/') return `/${locale}`;

  return `/${locale}${_to}`;
};

export const LocalizedLink = forwardRef(function LocalizedLink({ to, children, ...props }, ref) {
  const { isGatsby } = getFramework();
  const { locale } = useTranslationContext();
  const path = generateToPath(to, locale);
  const pathWithoutParams = path.split('?')[0];

  if (isGatsby || GATSBY_PATHS.includes(pathWithoutParams)) {
    return <a {...props} href={path} ref={ref}>{children}</a>;
  }

  return (
    <Link
      {...props}
      href={path}
      ref={ref}
    >
      {children}
    </Link>
  );
});
