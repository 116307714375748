const GATSBY = 'gatsby';
const NEXTJS = 'nextjs';

const getFramework = () => {
  const framework = process.env.GATSBY_FRAMEWORK ?? process.env.NEXT_PUBLIC_FRAMEWORK ?? 'unknown';

  return ({
    framework,
    isGatsby: framework === GATSBY,
  });
};

const withFramework = ({ gatsby, next }) => {
  const { isGatsby } = getFramework();

  return isGatsby ? gatsby() : next();
};

module.exports = {
  GATSBY,
  NEXTJS,
  getFramework,
  withFramework,
};
